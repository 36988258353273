import { Container } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import logo from "../../../assets/logo.png";
import styled from "styled-components";
import ActionButton from "../ActionButton";

const AboutMe = () => {
  return (
    <ColoredContainer>
      <Container>
        <Box>
          <ContentContainer>
            <Heading>So, Who am I?</Heading>
            <SubHeading>
              Hi, I'm Aditya Singh, a certified personal trainer, dietician, and
              competitive bodybuilder. My mission is to help men combat the
              decline in testosterone levels and sperm count caused by Endocrine
              Disrupting Chemicals. With testosterone levels dropping by an
              average of 1% each year over the past 50 years, it's crucial to
              take action.
              <br />
              <br />
              In my class, you'll find practical advice and strategies to
              optimize your hormonal health and overall well-being naturally.
              Let's work together to reclaim your vitality and achieve your
              fitness goals.
            </SubHeading>
            <ActionButton smallButton />
          </ContentContainer>
          <ImageContainer>
            <img width={"100%"} src={logo} />
          </ImageContainer>
        </Box>
      </Container>
    </ColoredContainer>
  );
};

export default AboutMe;

export const Heading = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const SubHeading = styled(Heading)`
  font-size: 20px;
  font-weight: normal;
`;

const ColoredContainer = styled.div`
  background-color: white;
  padding: ${isMobile ? "15px 0 30px 0px" : "30px 0 30px 0"};
`;

const Box = styled.div`
  display: flex;
  flex-direction: ${isMobile ? "column-reverse" : "row"};
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
