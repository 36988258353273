import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Col, Row } from "antd";
import { isMobile } from "react-device-detect";
import logo from "../../assets/logo.png"
import HeadingUnderline from "../../common/components/HeadingUnderline";

const Spacer = styled.div`
  height: 50px;
`;

const PText = styled.p`
  margin-bottom: 0px;
  text-align: center;
`;

const ValueItemParent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ showWhite }) => showWhite && "white"};
`;

const ValueItem = styled.div`
  display: flex;
  background: ${({ showWhite }) => showWhite && "white"};
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 1px 11px -2px #000000;
  border-radius: 8px;
  width: 300px;
  min-height: 250px;
  @media (max-width: 991px) {
    min-height: 284px;
  }
  @media (max-width: 767px) {
    min-height: 250px;
  }
`;

const ValueItemImage = styled.img`
  height: 80px;
  margin-bottom: 10px;
`;

const ValueItemHeading = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: black;
  margin-bottom: 10px;
  white-space: nowrap;
`;

const BGImage = styled.div`
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  height: 230px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    height: 145;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    margin-bottom: 20px;
  }
  }
  @media (max-width: 767px) {
    height: 130px;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    margin-bottom: 20px;
  }
`;

const WhoWeAre = () => (
  <div className="padding-30">
    <HeadingUnderline title="Who We Are" />
    {isMobile ? (
      <>
        <PText>
          <strong>
            Hello. Welcome to The Logical Traders. We’re glad you’re here.{" "}
          </strong>
        </PText>
        <PText>
          Have you ever lost money in the stock market because of
          self-proclaimed gurus? Don’t worry, you’re not alone.
        </PText>
        <PText>
          The gurus want you to buy their expensive stock market courses, tips,
          or secret strategies etc. But the bitter truth is that
        </PText>
        <Spacer />
        <PText>
          <strong>THEY. DON’T. WORK.</strong>
        </PText>
        <Spacer />
        <PText>
          The one thing that these experts have in common is that they never
          disclose their track record. They may show you occasional screen shots
          of profits, but never the long-term performance.{" "}
        </PText>
        <Spacer />
        <PText>
          Now ask yourself, why would someone hide their track record if they’re
          profitable? The answer is they wouldn’t unless they were losing money.
        </PText>
        <Spacer />
        <PText>
          And now ask yourself, can you really succeed by learning from people
          who aren’t profitable themselves? We highly doubt it.
        </PText>
        <Spacer />
        <PText>
          This is why we decided to start The Logical Traders. We believe you
          deserve to learn from experts who don’t just claim to be experts to
          fleece money from you, but are actually profitable, and transparent
          about their long-term performance.
        </PText>
      </>
    ) : (
      <>
        <PText>
          <strong>
            Hello. Welcome to The Logical Traders. We’re glad you’re here.{" "}
          </strong>
        </PText>
        <PText>
          Have you ever lost money in the stock market because of
          self-proclaimed gurus? Don’t worry, you’re not alone.
        </PText>
        <PText>
          The gurus want you to buy their expensive stock market courses, tips,
          or secret strategies etc. But the bitter truth is that
        </PText>
        <Spacer />
        <PText>
          <strong>THEY. DON’T. WORK.</strong>
        </PText>
        <Spacer />
        <PText>
          The one thing that these experts have in common is that they never
          disclose their track record. They may show you occasional
          <br /> screen shots of profits, but never the long-term performance.{" "}
        </PText>
        <Spacer />
        <PText>
          Now ask yourself, why would someone hide their track record if they’re
          profitable? The answer is they wouldn’t unless they were
          <br />
          losing money.
        </PText>
        <Spacer />
        <PText>
          And now ask yourself, can you really succeed by learning from people
          who aren’t profitable themselves? We highly doubt it.
        </PText>
        <Spacer />
        <PText>
          This is why we decided to start The Logical Traders. We believe you
          deserve to learn from experts who don’t just claim to be
          <br /> experts to fleece money from you, but are actually profitable,
          and transparent about their long-term performance.
        </PText>
      </>
    )}
  </div>
);

const OurValues = () => (
  <div className="padding-30">
    <HeadingUnderline title="Our Values" />
    <Row>
      <Col xs={24} sm={24} md={8}>
        <ValueItemParent>
          <ValueItem>
            <ValueItemImage src={logo} />
            <ValueItemHeading>Complete Transparency</ValueItemHeading>
            <PText>
              Unlike others, we share our record in the market with you so that
              you know you’re learning from credible experts.
            </PText>
          </ValueItem>
        </ValueItemParent>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <ValueItemParent>
          <ValueItem>
            <ValueItemImage src={logo} />
            <ValueItemHeading>Practical Learning</ValueItemHeading>
            <PText>
              We don't just share bookish
              <br />
              knowledge but we also share
              <br /> our unique trading secrets
            </PText>
          </ValueItem>
        </ValueItemParent>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <ValueItemParent>
          <ValueItem>
            <ValueItemImage src={logo} />
            <ValueItemHeading>Providing Value</ValueItemHeading>
            <PText>
              We leverage our expertise to deliver valuable insights to you that
              you won’t find anywhere else.
            </PText>
          </ValueItem>
        </ValueItemParent>
      </Col>
    </Row>
  </div>
);

const About = () => (
  <Row>
    <Spacer />

    <Col span="24">
      <BGImage>
        <div>
          <HeadingUnderline title="About Us" whitetext noMargin />
        </div>
      </BGImage>
    </Col>
    <Container>
      <WhoWeAre />
      <OurValues />
    </Container>
  </Row>
);

export default About;
