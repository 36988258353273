import { Container } from "react-bootstrap";
import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { isMobileOnly, isMobile, isTablet } from "react-device-detect";
import { theme } from "../utils/theme";
import { Spacer, ColoredContainer } from "../common/components";
import ActionButton from "../common/components/ActionButton";
import AboutMe from "../common/components/AboutMe";
import Subjects from "../common/components/Subjects";

const LandingPage = () => (
  <>
    <ColoredContainer>
      <Container></Container>
    </ColoredContainer>
    {/* <AboutMe /> */}
    {/* <Subjects /> */}
  </>
);

export default LandingPage;

const BoldHeadingBlackText = styled.span`
  color: white;
  font-size: ${isMobile ? "25px" : "35px"};
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${isMobile ? "30px" : "40px"};
`;

const SubHeadingWhiteText = styled.span`
  font-size: 20px;
  color: white;
  text-align: center;
`;

const InfoVideoContainer = styled.div`
  display: flex;
  min-height: 300px;
  padding: ${isMobile ? "10px 0" : "10px 40px"};
  justify-content: center;
  align-items: center;
  margin-top: ${isMobile ? 0 : "20px"};
`;

export const InfoBox = styled.div`
  flex: 1;
  background: transparent;
  min-height: 100%;
  border-radius: 5px;
  padding: ${isMobile ? "5px 0" : "0 10px"};
`;

export const InfoAnalyticsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const InfoAnalyticsBoxContainer = styled.div`
  padding: 0 10px;
  flex: 1;
`;

export const InfoAnalyticsBox = styled.div`
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  flex: 1;
  min-height: 80px;
  margin-bottom: 10px;
  padding: ${isMobile ? "10px" : "0 10px"};
`;

const VideoBox = styled.div`
  flex: 1;
  min-height: 100%;
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 80px;
  justify-content: space-evenly;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: 10px;
`;

export const MainNumber = styled.span`
  font-size: 25px;
  font-weight: bold;
`;

export const MainNumberYellow = styled(MainNumber)`
  color: ${theme.backgrounds.main};
`;

const BlackInfoAnalyticsContainer = styled(InfoAnalyticsContainer)`
  border-radius: 15px;
  padding: 5px 0;
  margin: 5px;
  flex-direction: column;
  font-size: 30px;
  color: white;
  font-weight: bold;
`;

const BlackInfoAnalyticsContainerMobile = styled(BlackInfoAnalyticsContainer)`
  font-size: 30px;
  text-align: center;
`;

const SubTextContainer = styled(BlackInfoAnalyticsContainer)`
  font-size: 20px;
  font-weight: normal;
`;

const SubTextContainerMobile = styled(SubTextContainer)`
  text-align: center;
`;

export const Text = styled.span`
  font-size: 15px;
  font-weight: bold;
`;

const EmptyDiv = styled.div`
  height: 150px;
  width: 1px;
`;
