import { Input } from "antd";
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { COURSE_PLAN_DETAILS } from "../BuyCourseButton";
import { FREE_COURSE } from "../../../constant";
import { setCouponCode } from "../../../actions/cart";
import { axiosAuth } from "../../../api";
import { API_ENDPOINTS } from "../../../api/apiEndpoints";
import { getIsCouponExpiryTime } from "../../../utils/helper";

const { Search } = Input;

const CouponCode = forwardRef((props, ref) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const codeParam = params.get("coupon-code");
  const [coupon, setCoupon] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const couponData = useSelector((state) => state.cart.coupon);
  const coursesInCart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const [countDown, setCountDown] = useState(0);
  const [countDownDate, setCountDownDate] = useState(null);

  const { toggleConfetti } = props;

  const getReturnValues = (remianingCountDown) => {
    if (remianingCountDown < 0) {
      return null;
    }
    // calculate time left
    const days = Math.floor(remianingCountDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (remianingCountDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (remianingCountDown % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((remianingCountDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds, remianingCountDown];
  };

  useEffect(() => {
    let intervalData = null;
    if (countDownDate) {
      intervalData = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000);

      return () => clearInterval(intervalData);
    }
    clearInterval(intervalData);
  }, [countDownDate]);

  useEffect(() => {
    if (couponData?._id) {
      const dateFrags = couponData.expiry.split("T");
      const timeFrags = dateFrags[1]?.split(":");
      const expiryDate = new Date(couponData.expiry);
      expiryDate.setHours(
        timeFrags[0]
          ? timeFrags[0] < 10
            ? Number(timeFrags[0].toString().charAt(1))
            : timeFrags[0]
          : 22
      );
      expiryDate.setMinutes(
        timeFrags[1]
          ? timeFrags[1] < 10
            ? Number(timeFrags[1].toString().charAt(1))
            : 0
          : 0
      );
      expiryDate.setSeconds(0);
      const currentTime = new Date().getTime();
      const expiryTime = expiryDate.getTime();

      if (expiryTime > currentTime) {
        setCountDownDate(expiryDate.getTime());
      }
    }
  }, [couponData]);

  useEffect(() => {
    if (countDown < -1200 && couponData?._id) {
      dispatch(setCouponCode(null));
      setCoupon(null);
      setMessage({
        error: true,
        text: "Coupon code expired",
      });
      setCountDownDate(null);
    }
  }, [countDown, couponData]);

  useImperativeHandle(ref, () => ({
    resetCoupon() {
      setCountDownDate(null);
      setCoupon(null);
      setMessage({
        error: true,
        text: "Coupon applicable only on bundled courses",
      });
    },
  }));

  const handleClick = async () => {
    if (!coupon) {
      return;
    }
    if (coursesInCart?.length !== 2) {
      setMessage({
        error: true,
        text: "Coupon applicable only on bundled courses",
      });
      return;
    }
    if (couponData) {
      setCountDownDate(null);
      setCoupon("");
      setMessage({
        error: false,
        text: "",
      });
      dispatch(setCouponCode(null));
      return;
    }
    setLoading(true);
    try {
      const couponCodeData = await axiosAuth.get(
        `${API_ENDPOINTS.VERIFY_COUPON}?code=${coupon.toLowerCase()}`
      );
      if (couponCodeData?.data?._id) {
        if (getIsCouponExpiryTime(couponCodeData?.data?.expiry)) {
          dispatch(setCouponCode(null));
          setMessage({
            error: true,
            text: "Coupon code expired",
          });
        } else {
          toggleConfetti(true);
          dispatch(setCouponCode(couponCodeData.data));
          const totalPrice =
            parseInt(COURSE_PLAN_DETAILS.course_amount, 10) +
            parseInt(FREE_COURSE.course_amount, 10);
          // parseInt(HUB.base_amount, 10);
          const totalDiscountPrice =
            couponCodeData.data.courseOne +
            couponCodeData.data.courseTwo +
            couponCodeData.data.courseThree +
            couponCodeData.data.courseFour;
          setMessage({
            error: false,
            text: `₹${totalPrice - totalDiscountPrice} off applied`,
          });
        }
      } else {
        dispatch(setCouponCode(null));
        setMessage({
          error: true,
          text: "Invalid coupon code",
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(setCouponCode(null));
      setMessage({
        error: true,
        text: "Invalid coupon code",
      });
    }
  };

  const applyAutoCoupon = async (customCoupon) => {
    if (!customCoupon) {
      return;
    }
    setLoading(true);
    try {
      const couponCodeData = await axiosAuth.get(
        `${API_ENDPOINTS.VERIFY_COUPON}?code=${customCoupon.toLowerCase()}`
      );
      if (couponCodeData?.data?._id) {
        if (getIsCouponExpiryTime(couponCodeData?.data?.expiry)) {
          dispatch(setCouponCode(null));
          setMessage({
            error: true,
            text: "Coupon code expired",
          });
        } else {
          toggleConfetti(true);
          dispatch(setCouponCode(couponCodeData.data));
          const totalPrice =
            parseInt(COURSE_PLAN_DETAILS.course_amount, 10) +
            parseInt(FREE_COURSE.course_amount, 10);
          // + parseInt(HUB.base_amount, 10);
          const totalDiscountPrice =
            couponCodeData.data.courseOne +
            couponCodeData.data.courseTwo +
            couponCodeData.data.courseThree +
            couponCodeData.data.courseFour;
          setMessage({
            error: false,
            text: `₹${totalPrice - totalDiscountPrice} off applied`,
          });
        }
      } else {
        dispatch(setCouponCode(null));
        setMessage({
          error: true,
          text: "Invalid coupon code",
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(setCouponCode(null));
      setMessage({
        error: true,
        text: "Coupon not valid",
      });
    }
  };

  useEffect(() => {
    if (codeParam) {
      setCoupon(codeParam);
      applyAutoCoupon(codeParam);
    }
  }, [codeParam]);

  const timerData = getReturnValues(countDown);
  return (
    <div style={{ fontWeight: "bold" }}>
      {couponData &&
        countDown > -1200 &&
        countDown !== 0 &&
        timerData &&
        !couponData?.isExpired && (
          <div>
            Coupon code expiring in{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>
              {timerData[1] < 10 ? `0${timerData[1]}` : timerData[1]}
              Hr&nbsp;:&nbsp;
              {timerData[2] < 10 ? `0${timerData[2]}` : timerData[2]}
              Min&nbsp;:&nbsp;
              {timerData[3] < 10 ? `0${timerData[3]}` : timerData[3]}
              Sec
            </span>
          </div>
        )}
      <Search
        className="coupon-code"
        onSearch={handleClick}
        value={coupon}
        placeholder="Enter coupon code"
        enterButton={couponData ? "Clear" : "Apply"}
        size="large"
        onChange={(e) => setCoupon(e.target.value)}
        loading={loading}
      />
      {message && (
        <p style={{ color: message?.error ? "red" : "green" }}>
          {message?.text ?? ""}
        </p>
      )}
    </div>
  );
});

export default CouponCode;
