import { Col, Row } from 'antd';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { shuffle } from 'lodash';
import blackComa from '../../../assets/balck-coma.png';
import ALL_TESTIMONIALS from './constant';
import BuyCourseButton from '../BuyCourseButton';
import HeadingUnderline from '../HeadingUnderline';
import { TestimonialsSlider } from '..';
import { theme } from '../../../utils/theme';

export const NameList = styled.ul`
  list-style: none;
  padding-left: 15px;
  li {
    text-align: left;
  }
  li:first-child {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  li:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #554d56;
  }
`;

export const UserImage = styled.img`
  height: 100px;
  width: 100px !important;
  border: ${({ noBorder }) => (noBorder ? 'none' : '2px solid #1A9C5B !important')};
  border-radius: 50px;
  object-fit: cover;
  @media (max-width: 767px) {
    height: 75px;
    width: 75px !important;
  }
`;

export const CommaImg = styled.img`
  padding-top: 4px;
  height: 47px;
  width: 63px !important;
  margin-bottom: 15px;
`;

export const TestimonialText = styled.p`
  margin-bottom: 0;
  text-align: center;
  padding: 0 15px;
  font-family: GilroyRegular;
  font-size: 16px;
  font-weight: 600;
`;

export const Name = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

export const Position = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  color: #1A9C5B;
`;

export const Location = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const Testimonials = ({
  showNoMargin,
  showGreyBg,
  hideButton,
  hideHeading,
}) => {
  const shuffledList = shuffle(ALL_TESTIMONIALS);
  return (
    <Container className={`testimonials-parent ${showGreyBg && 'grey-bg'}`}>
      {!hideHeading && (
        <Row justify="center" className="blogs-brief">
          <Col>
            <HeadingUnderline title="Testimonials" />
          </Col>
        </Row>
      )}
      <TestimonialsSlider
        className={`testimonial-slider ${
          showNoMargin && 'testimonial-slider-content'
        }`}
      >
        <Carousel
          showThumbs={false}
          showIndicators
          swipeable={false}
          showStatus={false}
          showArrows
          infiniteLoop
          autoPlay={false}
          interval={10000}
          stopOnHover
          useKeyboardArrows
          transitionTime={800}
        >
          {shuffledList.map((x) => (
            <div key={x.name}>
              <Row className={`${showGreyBg && 'grey-bg'}`}>
                <Col
                  lg={{ span: 14, offset: 5 }}
                  md={{ span: 14, offset: 5 }}
                  sm={{ span: 14, offset: 5 }}
                  xs={{ span: 20, offset: 2 }}
                >
                  <Row
                    style={{
                      background: theme.backgrounds.main,
                      marginBottom: '125px',
                    }}
                    className={`mb-20 slider-box ${
                      !isMobileOnly && 'padding-20-all'
                    }`}
                  >
                    <Col span={24}>
                      <div
                        style={{ position: 'relative', paddingBottom: '40px' }}
                      >
                        <CommaImg src={blackComa} />
                        <TestimonialText>{x.content}</TestimonialText>
                        {x.image ? (
                          <ImageDiv>
                            <UserImage src={x.image} />
                            <span>
                              <Name>{x.name}</Name>
                              {x.position && (
                                <Position>
                                  &nbsp;
                                  {x.position}
                                </Position>
                              )}
                            </span>
                          </ImageDiv>
                        ) : (
                          <InitialsDiv>
                            <div className="initials-testimonials">
                              <span>{x.name.charAt(0)}</span>
                            </div>
                            <span>
                              <Name>{x.name}</Name>
                            </span>
                          </InitialsDiv>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ))}
        </Carousel>
      </TestimonialsSlider>
      {!hideButton && (
        <Row
          justify="center"
          className="mt-15"
          style={{ marginBottom: '15px' }}
        >
          <BuyCourseButton flatButton />
        </Row>
      )}
    </Container>
  );
};

export default Testimonials;

const ImageDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: -115px;
  @media (max-width: 767px) {
    bottom: -80px;
  }
`;

const InitialsDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: -70px;
  @media (max-width: 767px) {
    bottom: -60px;
  }
`;
