import { Outlet, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Footer from "./common/components/Footer";
import Header from "./common/components/Header/Header";
import { BLOCKED_PATHNAME } from "./constant";
import { theme } from "./utils/theme";

const RootLayout = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const headerParam = params.get("header");
  return (
    <ThemeProvider theme={theme}>
      {/* {!BLOCKED_PATHNAME.includes(location?.pathname) && !headerParam && (
        <Header />
      )} */}
      <Outlet />
      <Footer />
    </ThemeProvider>
  );
};

export default RootLayout;
