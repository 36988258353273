import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import HeadingUnderline from "../../common/components/HeadingUnderline";

const StyledSpan = styled.span`
  font-weight: 400;
  font-size: 14px;
`;

const Spacer = styled.div`
  height: 20px;
`;

const SmallSpacer = styled.div`
  height: 10px;
`;

const PrivacyPolicy = () => (
  <Container>
    <Spacer />
    <HeadingUnderline title="Privacy Policy" />
    <p>
      At InetlliHome Automation, we respect your privacy and are committed to
      protecting your personal information.
    </p>
    <p>
      This privacy policy explains how we collect, use, and safeguard the data
      you provide through our Facebook lead generation ads.
    </p>
    <Spacer />
    <h5>
      <strong>Information We Collect</strong>
    </h5>
    <p>
      When you submit your information through our online ads, we may collect
      your name, email address, phone number, and other relevant details needed
      to provide our services or respond to your inquiries.
    </p>
    <Spacer />
    <h5>
      <strong>How We Use Your Information</strong>
    </h5>
    <p>We use the information you provide to:</p>
    <ul>
      <li>Contact you about our home automation products and services</li>
      <li>Respond to your inquiries and requests</li>
      <li>Provide relevant updates and offers</li>
      <li>
        Improve our products and services based on feedback Data Protection and
        Security
      </li>
      <li>
        We take appropriate measures to protect your data from unauthorized
        access, disclosure, alteration, or destruction.{" "}
      </li>
      <li>
        Your personal information will only be shared with trusted partners and
        vendors who assist us in delivering our services and only for the
        purposes outlined in this policy.
      </li>
    </ul>
    <Spacer />
    <h5>
      <strong>Your Rights</strong>
    </h5>
    <br />
    <p>
      You have the right to request access to, correction, or deletion of your
      personal information. You can opt out of receiving communications from us
      at any time by contacting us or using the unsubscribe link in our emails
    </p>
    <SmallSpacer />
    <Spacer />
  </Container>
);

export default PrivacyPolicy;
