import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./routes/LandingPage";
import { RecoilRoot } from "recoil";
import { Provider } from "react-redux";
import configureStore from "./store";
import RootLayout from "./RootLayout";
import { ToastContainer } from "react-toastify";

//Styles
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import About from "./routes/about";
import PrivacyPolicy from "./routes/privacyPolicy";
import TermsConditions from "./routes/termsConditions";
import FAQS from "./routes/faqs";
import ContactUs from "./routes/contactUs";
import Disclaimer from "./routes/disclaimer";
import RefundPolicy from "./routes/refundPolicy";
import Login from "./routes/login";
import AuthRoute from "./AuthRoute";
import Dashboard from "./routes/dashboard";
import Checkout from "./routes/signup";
import Cart from "./common/components/cart";

const isAuthenticated = () => {
  return !!localStorage.getItem("token"); // Replace with your auth logic
};

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    // loader() {
    //   // Our root route always provides the user, if logged in
    //   return { user: fakeAuthProvider.username };
    // },
    Component: RootLayout,
    children: [
      {
        index: true,
        Component: PrivacyPolicy,
      },
      {
        path: "login",
        Component: Login,
      },
      {
        path: "register",
        Component: Checkout,
      },
      {
        path: "cart",
        Component: Cart,
      },
      // {/* <Route
      //   exact
      //   path="/cart"
      //   component={() =>
      //     !localStorage.getItem("authToken") ? <Cart /> : <Redirect to="/" />
      //   }
      // /> */}
      {
        path: "about-us",
        Component: About,
      },
      {
        path: "privacy-policy",
        Component: PrivacyPolicy,
      },
      {
        path: "terms-and-conditions",
        Component: TermsConditions,
      },
      {
        path: "faqs",
        Component: FAQS,
      },
      {
        path: "contact-us",
        Component: ContactUs,
      },
      {
        path: "disclaimer",
        Component: Disclaimer,
      },
      {
        path: "refund-policy",
        Component: RefundPolicy,
      },
      {
        path: "/dashboard",
        element: (
          <AuthRoute isAuthenticated={isAuthenticated()}>
            <Dashboard />
          </AuthRoute>
        ),
      },
    ],
  },
]);

function App() {
  return (
    <Provider store={configureStore()}>
      <RecoilRoot>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          newestOnTop
          pauseOnHover
        />
        <div className="margin-app">
          <RouterProvider router={router} />
        </div>
      </RecoilRoot>
    </Provider>
  );
}

export default App;
